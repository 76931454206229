<template>
    <div>
        <h1>Az oldal nem található!</h1>
    </div>
</template>

<script>
export default {
    name: "NotFound"
};
</script>

<style scoped></style>
